import React from 'react'
import { navigate } from 'gatsby-link'

import Button from '../Button/Button'

import classes from './Forms.module.css'

import ArrowDown from '../../images/arrow_down-24px.svg'

export default class extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        domaine: 'Psychiatrie (Diagnostic/Médication)'
    }

    handleInputChange = (e) => {
        const state = {...this.state}
        state[e.target.getAttribute('name')] =  e.target.value

        this.setState({
            ...state
        })
    }

    handleLeadFormClicked = () => {
        navigate('/reservation', {
            state: {
                from: 'leadform',
                data: this.state
            }
        })
    }

    render() {
        const { domaine } = this.state;

        
        let canBook = true;
        if(domaine === "Psychothérapie/ Consultation Individuelle" ||
            domaine === "Groupe thérapeutique" ||
            domaine === "Thérapie de couple" || domaine === "Thérapie de familiale") {
            canBook = false;
        }

        return (
            <div className={classes.FormWrapper}>
                <div className={classes.LeadFormHeader}>Formulaire de Rendez-Vous</div>
                <div className={classes.FormBody}>
                    <div style={{marginBottom: 10}}>
                        <div className={classes.InputLabelLeadForm}>Prénom(s)</div>
                        <input name="firstName" className={classes.InputStyle} onChange={(e) => this.handleInputChange(e)}/>
                    </div>
                    <div style={{marginBottom: 10}}>
                        <div className={classes.InputLabelLeadForm}>Nom(s)</div>
                        <input name="lastName" className={classes.InputStyle} onChange={(e) => this.handleInputChange(e)}/>
                    </div>
                    <div style={{marginBottom: 10}}>
                        <div className={classes.InputLabelLeadForm}>Téléphone</div>
                        <input name="phoneNumber" className={classes.InputStyle} onChange={(e) => this.handleInputChange(e)}/>
                    </div>
                    <div style={{marginBottom: 10}}>
                        <div className={classes.InputLabelLeadForm}>Email</div>
                        <input name="email" className={classes.InputStyle} onChange={(e) => this.handleInputChange(e)}/>
                    </div>
                    <div style={{marginBottom: 20}}>
                        <div className={classes.InputLabelLeadForm}>Domaine</div>
                        <select name="domaine" className={classes.SelectInput} style={{backgroundImage: `url(${ArrowDown})`}} onChange={(e) => this.handleInputChange(e)}>
                            <option value="Psychiatrie (Diagnostic/Médication)">Psychiatrie (Diagnostic/Médication)</option>
                            <option value="Psychothérapie/ Consultation Individuelle">Psychothérapie/ Consultation Individuelle</option>
                            <option value="Thérapie de couple">Thérapie de couple</option>
                            <option value="Thérapie de familiale">Thérapie de familiale</option>
                            <option value="Groupe thérapeutique">Groupe thérapeutique</option>
                            <option value="Je ne sais pas ...">Je ne sais pas ...</option>
                        </select>
                    </div>
                    {/* {!canBook && <div className={classes.AlertMessageWrapper}>
                        Désolé, mais malheureusement nous sommes dans l'incapacité de prendre de nouveaux patients dans ce domaine en ce moment.
                    </div> } */}
                    <div>
                        {/* <Button color="primary"  disabled={!canBook} text="Prendre rendez-vous" styles={styles} clicked={this.handleLeadFormClicked}/> */}
                        <Button color="primary" text="Prendre rendez-vous" styles={styles} clicked={this.handleLeadFormClicked}/>
                    </div>
                </div>

            </div>
        )
    }
}

const styles = {
    width:"100%",
    borderRadius: 0
}