import React, { Component } from 'react'
import Link from 'gatsby-link'
import Footer from '../components/Footer/Footer'
import LeadForm from '../components/Forms/LeadForm'
import MobileActions from '../components/MobileActions/MobileActions'
import Helmet from 'react-helmet'

import classes from '../layouts/Pages.module.css'
import Layout from '../layouts'

export default class extends Component {

    render() {
        
        return (
            <Layout location={this.props.location}>
                <div>
                    <Helmet>
                        <title>A Propos du Psy-Scan Institute</title>
                        <meta name="description" content="Le Psy-Scan Institute est un cabinet médical multidisciplinaire axé sur la prise en charge des consultations psychologiques et des traitements psychiatrique-psychothérapeutique intégré."/>
                    </Helmet>
                    <div>
                        <div style={{maxWidth: 1170, margin: 'auto', padding: '40px 15px 60px'}}>
                            <h1>À Propos de Nous</h1>
                            <div className={classes.faqContainer}>
                                <div className={classes.faqContentWrapper} style={{fontSize: '1.1rem'}}>
                                    <p>Le Psy-Scan Institute est un cabinet médical multidisciplinaire axé sur la prise en charge des consultations psychologiques et des traitements psychiatrique-psychothérapeutique intégré.</p>

                                    <p>Le Psy-Scan Institute regroupe des Psychologues et des Psychothérapeutes, spécialisés dans différentes approches thérapeutiques comme la thérapie cognitivo- comportementale (TCC), l’hypnose, la programmation neurolinguistique(PNL), le « eye movements desensitization and reprocessing » (EMDR), l’approche psychodynamique, familiale, systémique, etc. La supervision de l’équipe thérapeutique est sous la direction médicale d'un psychiatre ou d'un pédopsychiatre.</p>

                                    <p>Les thérapies sus mentionnées ici qui s’y pratiquent sont reconnues par leur efficacité par la FMH, surtout si elles sont associées dans une approche éclectique et biopsychosociale (traitement médical, psychologique et social). Bien que notre équipe présente des compétences générales pour prendre en charge tous les troubles psychiatriques et psychosociaux en ambulatoire pour les enfants, les adolescents les adultes, notre expertise est plus particulièrement reconnue dans les troubles suivants:</p>
                                    <ul style={{listStyleType: 'initial', paddingLeft: 40, paddingBottom: 20}}>
                                        <li>Troubles dépressifs uni ou bipolaires,</li>
                                        <li>Troubles anxieux,</li>
                                        <li>Troubles du stress post traumatiques,</li>
                                        <li>Troubles somatoformes douloureux</li>
                                        <li>Troubles psychosomatiques</li>
                                        <li>Troubles sexuels</li>
                                        <li>Troubles du sommeil</li>
                                        <li>Syndrome de fatigue chronique</li>
                                        <li>Troubles alimentaires,</li>
                                        <li>Troubles addictifs (toxiques, Internet, jeux pathologiques, sexe, travail, et autres)</li>
                                        <li>Troubles des conduites,</li>
                                        <li>Troubles des apprentissages scolaires et professionnels,</li>
                                        <li>Trouble de l’hyperactivité et du déficit d’attention (THADA),</li>
                                        <li>Certains troubles de la personnalité.</li>
                                    </ul>

                                    <p>Le setting thérapeutique peut se faire en individuel, en famille, en couple ou en groupe selon la demande ou les besoins.</p>

                                    <p>Le psychiatre ou le pédopsychiatre assure le diagnostic, la prescription des médicaments en cas de besoin, ainsi que le suivi médical. Il est le responsable de toutes les prises en charge et encadre l’équipe thérapeutique avec des supervisions sur le plan clinique ou dans le cadre des expertises.</p>

                                    <p>Tous les professionnels qui travaillent au Psy-Scan Institute parlent au moins deux langues, ce qui présente un atout linguistique majeur dans la prise en charge des patients d’origines culturelles diverses. En cas de langues autres que celles connues et parlées par les professionnels de notre équipe, nous faisons appel à la collaboration des médiateurs socioculturels et interprètes auprès du service de la cohésion multiculturelle du canton de Neuchâtel (SCOM).</p>

                                    <p>Toutes les thérapies médicalement indiquées qui se déroulent au <Link to="/">Psy-Scan Institute</Link> sont prises en charge par l’assurance maladie des soins obligatoires (LAMal).</p>

                                </div>
                                <div className={classes.faqFormWrapper}>
                                    <LeadForm/>
                                </div>
                            </div>

                        </div>
                    </div>
                    <Footer/>
                    {this.props.scrollPosition > 600
                        ? <MobileActions/>
                        : null
                    }
                </div>
            </Layout>
        )
    }
}

