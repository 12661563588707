import React from 'react'
import Link from 'gatsby-link'

import classes from './Footer.module.css'

export default (props) => (
    <div>
        <div style={{backgroundColor: '#333333'}}>
            <div style={{maxWidth: 1170, padding: '40px 15px', margin: 'auto'}}>
                <div style={{color: '#fff'}} className={classes.FooterLinksWrap}>
                    <ul style={{display: 'flex'}} className={classes.UlLinks}>
                        <li style={{padding: '0 10px'}}><Link style={{color: '#fff'}} to="/carrieres">Carrières</Link></li>
                        <li style={{padding: '0 10px'}}>|</li>
                        <li style={{padding: '0 10px'}}><Link style={{color: '#fff'}} to="/politique-de-confidentialite">Confidentialité</Link></li>
                        <li style={{padding: '0 10px'}}>|</li>
                        <li style={{padding: '0 10px'}}><Link style={{color: '#fff'}} to="/contact">Contact</Link></li>
                    </ul>
                </div>

                <div style={{color: '#fff', fontSize: '0.8rem', textAlign: 'center'}}>
                    <div className={classes.CityLink}>
                        <Link to="/">Acceuil</Link> | <Link to="/neuchatel">Neuchâtel</Link>
                         {/* | <Link to="/lausanne">Lausanne</Link> */}
                    </div>
                    <div>© 2003-{(new Date()).getFullYear()} Psy-Scan Institute™ • Psychiatrie et psychothérapie</div>
                </div>
            </div>
        </div>

        <div style={{backgroundColor: '#000', paddingBottom: 100}}>
            <div style={{maxWidth: 1170, padding: '40px 20px 20px', margin: 'auto', fontSize: '1rem', color: 'white', textAlign: 'center'}}>
                <div >
                    Si vous êtes en situation de crise ou une personne est en danger, n'utilisez pas ce site. <Link to="/ligne-de-crise">Ces ressources</Link> peuvent vous fournir une aide immédiate.
                    <span style={{fontWeight: '600'}}> <br />CUP: <a href="tel:+41327551515">032 755 15 15</a></span>
                </div>
            </div>
        </div>
    </div>
)